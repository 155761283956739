import React, { FC } from 'react';

import { ILoginSocialBarProps, Layout } from '../LoginSocialBar.types';

import styles from './style/LoginSocialBar.scss';
import LoggedInSocialBar from './components/loggedInSocialBar';
import LoggedOffSocialBar from './components/loggedOffSocialBar';

const showAvatar = (displayMode: Layout) =>
  displayMode === Layout.AvatarOnly ||
  displayMode === Layout.AvatarAndText ||
  displayMode === Layout.MemberInitials;

const LoginSocialBar: FC<ILoginSocialBarProps> = props => {
  const {
    id,
    isLoggedIn,
    userName,
    loggedInText,
    loggedOutText,
    logOutText,
    direction,
    useNativeMenu,
    menuItems,
    iconItems,
    currentPrimaryPageHref,
    onLogin,
    onLogout,
    navigateTo,
    onMouseEnter,
    onMouseLeave,
    isMenuOpen,
    onMenuOpen,
    onMenuClose,
    translate,
    displayMode,
    avatarUri,
    iconSize,
    defaultAvatarSvgContent,
    showBellIcon,
    arrowShape,
    menuItemsAlignment,
  } = props;

  const closeMenuOnClickOutside: React.FocusEventHandler = ({
    currentTarget,
    relatedTarget,
  }) => {
    if (!relatedTarget || !currentTarget.contains(relatedTarget as Node)) {
      onMenuClose();
    }
  };

  const showIcons = showBellIcon && !!iconItems.length;

  return (
    <div
      id={id}
      className={styles.root}
      onBlur={
        isLoggedIn && !useNativeMenu ? closeMenuOnClickOutside : undefined
      }
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isLoggedIn ? (
        <LoggedInSocialBar
          avatarUri={avatarUri}
          isLoggedIn={isLoggedIn}
          userName={userName}
          id={id}
          displayMode={displayMode}
          iconSize={iconSize}
          defaultAvatarSvgContent={defaultAvatarSvgContent}
          loggedInText={loggedInText}
          currentPrimaryPageHref={currentPrimaryPageHref}
          direction={direction}
          menuItems={menuItems}
          iconItems={iconItems}
          showIcons={showIcons}
          isMenuOpen={isMenuOpen}
          onMenuOpen={onMenuOpen}
          logOutText={logOutText}
          navigateTo={navigateTo}
          onLogout={onLogout}
          onMenuClose={onMenuClose}
          showAvatar={showAvatar(displayMode)}
          useNativeMenu={useNativeMenu}
          translate={translate}
          arrowShape={arrowShape}
          menuItemsAlignment={menuItemsAlignment}
        />
      ) : (
        <LoggedOffSocialBar
          id={id}
          displayMode={displayMode}
          iconSize={iconSize}
          defaultAvatarSvgContent={defaultAvatarSvgContent}
          isLoggedIn={isLoggedIn}
          userName={userName}
          avatarUri={avatarUri}
          loggedOutText={loggedOutText}
          showAvatar={showAvatar(displayMode)}
          handleLogin={onLogin}
          translate={translate}
        />
      )}
    </div>
  );
};

export default LoginSocialBar;
